import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchMediaData } from '../utils/api';

const CaseStudy = ({
  id,
  idMedia,
  title,
  excerpt,
}) => {
  console.log(title);

  const [urlMedia, setURLMedia] = useState({});

  useEffect(() => {
    const getMedia = async () => {
      if (!idMedia) return;

      const data = await fetchMediaData(idMedia);
      if (data) {
        setURLMedia(data);
      } else {
        console.log("ERROR: on fetch media");
      }
    };
    getMedia();
  }, [idMedia]);


  return (
    <Link to={`/case-study/${id}`} className='article-case-study'>
      <article>
        <div className='article-body'>
          <div className='article-img-container'>
            {urlMedia.source_url && (
              <img
                src={urlMedia.source_url}
                className='article-img'
                alt={title}
              />
            )}
          </div>
          <div className='article-header'>
            <h2>{title}</h2>
          </div>
          <div className='article-content-container'>
            <p
              className='article-content-excerpt'
              dangerouslySetInnerHTML={{ __html: excerpt }}
            />
          </div>
        </div>
      </article>
    </Link>
  );
};

export default CaseStudy;
