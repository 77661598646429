import React from "react";
import { Link } from "react-router-dom";

const Challenge = ({ id, title, excerpt }) => {
  return (
    <Link to={`/challenge/${id}`} className='article-case-study'>
      <article>
        <div className='article-body'>
          <div className='article-card-container idea-de-proyecto'>
            <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
            <p
              className='article-content-excerpt'
              dangerouslySetInnerHTML={{ __html: excerpt }}
            />
          </div>
        </div>
      </article>
    </Link>
  );
};

export default Challenge;
