import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  return (
    <header className='header-container'>
      <div className='logo-container'>
        <Link to='/'>
          <h1>
            Juan Falibene <span className='slash'>/</span>{" "}
            <span className='subtitle'> Blog</span>
          </h1>
        </Link>
      </div>
      <button className='menu-toggle' onClick={toggleMenu}>
        Menu
      </button>
      <div className={`menu-links ${menuOpen ? "open" : ""}`}>
        <Link to='/case-studies'>Casos de Estudio</Link>
        <Link to='/archive'>Archivo Web</Link>
        <Link to='/challenges'>Aprendizaje</Link>
        <a
          href='https://juanfalibene.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          Portfolio
        </a>
      </div>
    </header>
  );
};

export default Header;
