import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchData } from "../utils/api";

const CaseStudyPage = () => {
  const { id } = useParams();
  const [caseStudy, setCaseStudy] = useState(null);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

  useEffect(() => {
    const fetchCaseStudy = async () => {
      const data = await fetchData(
        `https://blog.juanfalibene.com/wp-json/wp/v2/case-studies/${id}`
      );
      if (data) {
        setCaseStudy(data);
      }
    };
    fetchCaseStudy();
  }, [id]);

  if (!caseStudy) {
    return <p className='loading'>Cargando...</p>;
  }

  return (
    <article className='case-study-page'>
      <header className='case-study-page-header'>
        <h1>{caseStudy.title.rendered}</h1>
        <h2 className='case-study-page-subtitle'>
          {caseStudy.acf.categories.join(", ")}
        </h2>
        {caseStudy.acf.mockups[0] && (
          <img
            src={caseStudy.acf.mockups[0].full_image_url}
            alt={caseStudy.acf.mockups[0].title}
            className='case-study-page-mockup-desktop'
          />
        )}
      </header>
      <section className='case-study-page-body'>
        <aside className='case-study-page-sidebar'>
          <h3>{caseStudy.acf.subtitulo}</h3>
          <div className='case-study-page-tech'>
            <ul>
              {caseStudy.acf.tech.map((tech, index) => (
                <li key={index}>{tech}</li>
              ))}
            </ul>
          </div>
          <a
            href={caseStudy.acf.url}
            rel='noreferrer noopener'
            target='_blank'
            className='case-study-page-link'
          >
            Ver Sitio Online
          </a>
        </aside>
        <div className='case-study-page-content'>
          <div className='case-study-page-content-inner'>
            <div
              dangerouslySetInnerHTML={{ __html: caseStudy.content.rendered }}
            />
          </div>
          {caseStudy.acf.video && (
            <div className='case-study-page-video'>
              <video width='100%' height='480' autoPlay={!isMobile} muted loop>
                <source src={caseStudy.acf.video} type='video/mp4' />
              </video>
            </div>
          )}
          {caseStudy.acf.mockups && (
            <div className='case-study-page-mockups'>
              {
                <div className='case-study-page-mockup-mobile-container'>
                  {caseStudy.acf.mockups.slice(1, 3).map((mockup, index) => (
                    <img
                      src={mockup.full_image_url}
                      key={index}
                      alt={mockup.title}
                      className='case-study-page-mockup-mobile'
                    />
                  ))}
                  {caseStudy.acf.video_mobile && (
                    <div className='case-study-page-video-mobile'>
                      <video
                        width='100%'
                        height='480'
                        autoPlay={!isMobile}
                        muted
                        loop
                      >
                        <source
                          src={caseStudy.acf.video_mobile}
                          type='video/mp4'
                        />
                      </video>
                    </div>
                  )}
                </div>
              }
            </div>
          )}
        </div>
      </section>
    </article>
  );
};

export default CaseStudyPage;
