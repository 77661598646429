import React, { useState, useEffect } from "react";

const Emoji = () => {
  // emoji
  const [currentEmoji, setCurrentEmoji] = useState("🙂");
  const emojiArray = [
    "🙂",
    "👍",
    "🙃",
    "🤔",
    "💭",
    "🧠",
    "🤯",
    "😓",
    "🧑‍💻",
    "☕",
    "🎉",
    "🌱",
    "🌐",
    "💻",
    "🪫",
    "🔋",
    "📚",
    "📧",
    "📆",
    "⏳",
    "💸",
    "🤝",
    "😮‍💨",
    "📝",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * emojiArray.length);
      setCurrentEmoji(emojiArray[randomIndex]);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return <span className='emoji'>{currentEmoji}</span>;
};

export default Emoji;
