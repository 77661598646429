import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchData } from "../utils/api";

const ChallangePage = () => {
  const { id } = useParams();
  const [challenge, setChallenge] = useState(null);

  useEffect(() => {
    const getChallenge = async () => {
      const data = await fetchData(
        `https://blog.juanfalibene.com/wp-json/wp/v2/pruebas/${id}`
      );
      if (data) {
        setChallenge(data);
      }
    };
    getChallenge();
  }, [id]);

  if (!challenge) {
    return <p className='loading'>Cargando...</p>;
  }

  return (
    <article className='case-study-page'>
      <header className='case-study-page-header'>
        <h1>{challenge.title.rendered}</h1>
        <h2
          className='case-study-page-subtitle'
          dangerouslySetInnerHTML={{ __html: challenge.excerpt.rendered }}
        />
        <div dangerouslySetInnerHTML={{ __html: challenge.content.rendered }} />
      </header>
    </article>
  );
};

export default ChallangePage;
