// src/utils/api.js
// posts
export const fetchData = async (endpoint) => {
  try {
    const response = await fetch(endpoint);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("ERROR: on fetch data");
      return null;
    }
  } catch (error) {
    console.error("ERROR ON LOAD DATA", error);
    return null;
  }
};

// media url from ID
export const fetchMediaData = async (idMedia) => {
  const endpoint = `https://blog.juanfalibene.com/wp-json/wp/v2/media/${idMedia}`;
  return await fetchData(endpoint);
};

// category name from ID
export const fetchCategoryName = async (idCategory) => {
  const endpoint = `https://blog.juanfalibene.com/wp-json/wp/v2/categories/${idCategory}`;
  return await fetchData(endpoint);
};

// tagname from ID
export const fetchTagName = async (idTag) => {
  const endpoint = `https://blog.juanfalibene.com/wp-json/wp/v2/tags/${idTag}`;
  return await fetchData(endpoint);
};

// date
export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("es-ES", options);
  return formattedDate;
};
