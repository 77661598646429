import React, { useEffect, useState } from "react";
import CaseStudy from "./CaseStudy";
import { fetchData } from "../utils/api";

const CaseStudies = () => {
  const [caseStudies, setCaseStudies] = useState([]);

  useEffect(() => {
    const fetchCaseStudies = async () => {
      const data = await fetchData(
        "https://blog.juanfalibene.com/wp-json/wp/v2/case-studies"
      );
      if (data) {
        setCaseStudies(data);
      }
    };
    fetchCaseStudies();
  }, []);

  return (
    <section className='case-container'>
      <div className='case-container-header'>
        <h1>Casos de Estudio</h1>
        <p>
          A través de algunos proyectos seleccionados, he adquirido experiencia
          en la resolución de problemas, la planificación e implementación de
          soluciones, y la ejecución de código personalizado. Pero, sobre todo,
          he aprendido a ser constante y a trabajar con pasión en el ámbito web.
        </p>
        <p>
          Mis habilidades incluyen desarrollo, diseño, creación de wireframes,
          diseño UI/UX, asesoramiento y consultoría, formación...
        </p>
      </div>
      <div className='case-articles'>
        {caseStudies.length > 0 ? (
          caseStudies.map((caseStudy) => (
            <CaseStudy
              key={caseStudy.id}
              id={caseStudy.id}
              idMedia={caseStudy.featured_media}
              title={caseStudy.title.rendered}
              excerpt={caseStudy.excerpt.rendered}
              content={caseStudy.content.rendered}
              acf_url={caseStudy.acf.url}
              acf_tech={caseStudy.acf.tech}
              acf_video={caseStudy.acf.video}
            />
          ))
        ) : (
          <p className='loading'>Cargando...</p>
        )}
      </div>
    </section>
  );
};

export default CaseStudies;
