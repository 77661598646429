import React, { useState, useEffect } from "react";
import Challenge from "./Challenge";
import SimpleProject from "./SimpleProject";
import { fetchData } from "../utils/api";

const Challenges = () => {
  const [challenges, setChallenges] = useState([]);
  const [simpleProjects, setSimpleProjects] = useState([]);

  useEffect(() => {
    const fetchChallenges = async () => {
      const data = await fetchData(
        "https://blog.juanfalibene.com/wp-json/wp/v2/pruebas?per_page=20"
      );
      if (data) {
        setChallenges(data);
      }
    };
    fetchChallenges();
  }, []);

  useEffect(() => {
    const fetchSimpleProjects = async () => {
      const data = await fetchData(
        "https://blog.juanfalibene.com/wp-json/wp/v2/proyecto-simple?per_page=20"
      );
      if (data) {
        setSimpleProjects(data);
        console.log(data);
      }
    };
    fetchSimpleProjects();
  }, []);

  return (
    <>
      <section className='case-container' key='simple-projects'>
        <div className='case-container-header'>
          <h1>Proyectos Simples</h1>
          <p>
            Algunos proyectos simples que he realizado a lo largo del tiempo.
            Pueden ser de gran ayuda para aprender sobre el mundo de la
            programación. Conociendo de manera práctica librerías, conceptos y
            ejercicios mediante tutoriales e ideas simples.
          </p>
        </div>
        <div className='case-articles'>
          {simpleProjects.length > 0 ? (
            simpleProjects.map((simple) => (
              <SimpleProject
                key={simple.id}
                id={simple.id}
                title={simple.title.rendered}
                excerpt={simple.excerpt.rendered}
                link={simple.acf.link_a_proyecto}
                idMedia={simple.featured_media}
              />
            ))
          ) : (
            <p className='loading'>Cargando...</p>
          )}
        </div>
      </section>
      <section className='case-container' key='challenges'>
        <div className='case-container-header'>
          <h1>Pruebas Técnicas</h1>
          <p>
            Pruebas técnicas realizadas desde los comienzos del curso de
            formación profesional de Desarrollo Web Front End en CIFO
            Hospitalet, con el objetivo de afianzar conceptos y conocimientos a
            la par de las tareas realizadas para evaluación.
          </p>
        </div>
        <div className='case-articles'>
          {challenges.length > 0 ? (
            challenges.map((challenge) => (
              <Challenge
                key={challenge.id}
                id={challenge.id}
                title={challenge.title.rendered}
                excerpt={challenge.excerpt.rendered}
                content={challenge.content.rendered}
              />
            ))
          ) : (
            <p className='loading'>Cargando...</p>
          )}
        </div>
      </section>
    </>
  );
};

export default Challenges;
