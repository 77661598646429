import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  fetchData,
  formatDate,
  fetchCategoryName,
  fetchTagName,
} from "../utils/api";

const Post = () => {
  const location = useLocation();
  const pathname = location.pathname + location.search;
  const params = new URLSearchParams(pathname.split("post/")[1]);
  const id = params.get("id");
  const postCategory = params.get("category");
  console.log(id, postCategory);
  const [post, setPost] = useState(null);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const getPost = async () => {
      const data = await fetchData(
        `https://blog.juanfalibene.com/wp-json/wp/v2/posts/${id}`
      );
      if (data) {
        // Set post data
        setPost(data);
        // Fetch category names for each category ID in the post
        const categoryNames = await Promise.all(
          data.categories.map((categoryId) => fetchCategoryName(categoryId))
        );
        setCategories(categoryNames.map((category) => category.name));
        // Fetch tag names for each tag ID in the post
        const tagNames = await Promise.all(
          data.tags.map((tagId) => fetchTagName(tagId))
        );
        setTags(tagNames.map((tag) => tag.name));
      }
    };
    getPost();
  }, [id]);

  if (!post) {
    return <p className='loading'>Cargando...</p>;
  }

  return (
    <article className='post-page'>
      <header className={`post-page-header ${postCategory}`}>
        <h1>{post.title.rendered}</h1>
      </header>
      <section className='post-page-body'>
        <aside className='post-page-sidebar'>
          <h2
            className='post-page-subtitle'
            dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
          />
          {/* List of category & tags names */}
          <div className='post-page-tech'>
            <ul>
              <li className='date'>{formatDate(post.date)}</li>
              {categories.map((category, index) => (
                <li key={index}>{category}</li>
              ))}
            </ul>
            <ul>
              {tags.map((tag, index) => (
                <li key={index}>{tag}</li>
              ))}
            </ul>
          </div>
        </aside>
        <div className='post-page-content'>
          <div className='post-page-content-inner'>
            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
          </div>
        </div>
      </section>
    </article>
  );
};

export default Post;
