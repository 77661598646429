import React from "react";
import { Routes, Route, Navigate, HashRouter } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import CaseStudies from "./components/CaseStudies";
import CaseStudyPage from "./components/CaseStudyPage";
import Archive from "./components/Archive";
import Challenges from "./components/Challenges";
import ChallengePage from "./components/ChallengePage";
import Post from "./components/Post";
/*import "./fonts/FTSterlingTrial-Regular.woff";*/

const App = () => {
  return (
    <HashRouter>
      <div className='app'>
        <Header></Header>
        <Routes>
          <Route path='/' element={<Navigate to='/home' />} />
          <Route path='/home' element={<Home />} />
          <Route path='/case-studies' element={<CaseStudies />} />
          <Route path='/case-study/:id' element={<CaseStudyPage />} />
          <Route path='/archive' element={<Archive />} />
          <Route path='/challenges' element={<Challenges />} />
          <Route path='/challenge/:id' element={<ChallengePage />} />
          <Route path='/post' element={<Home />} />
          <Route path='/post/:id' element={<Post />} />
        </Routes>
      </div>
    </HashRouter>
  );
};

export default App;
